<template>
  <div class="content">
    <heads></heads>
    <div style="padding: 30px 120px;">
      <div class="main">
        <div class="main-head">
          <div class="main-head-tit">{{ $t('withdraw.tx') }}</div>
          <div class="line"></div>
          <div class="main-box">
            <div class="main-box-son">
              <div style="display: flex;align-items: center;margin-bottom: 16px;">
                <div class="circle">1</div>
                <div class="line2"></div>
              </div>
              <div>
                <div class="main-box-son-tit"> {{ $t('withdraw.tx2') }}</div>
                <div class="main-box-son-tit2">{{ $t('withdraw.tx3') }}</div>
              </div>
            </div>
            <div class="main-box-son">
              <div style="display: flex;align-items: center;margin-bottom: 16px;">
                <div class="circle">2</div>
                <div class="line2"></div>
              </div>
              <div>
                <div class="main-box-son-tit"> {{ $t('withdraw.tx4') }}</div>
                <div class="main-box-son-tit2">{{ $t('withdraw.tx5') }}</div>
              </div>
            </div>
            <div class="main-box-son">
              <div style="display: flex;align-items: center;margin-bottom: 16px;">
                <div class="circle">3</div>
              </div>
              <div>
                <div class="main-box-son-tit"> {{ $t('withdraw.tx6') }}</div>
                <div class="main-box-son-tit2">{{ $t('withdraw.tx7') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-head">
          <div class="main-footer">
            <div class="main-footer-left">
              <div style="width: 70%;">
                <div style="font-size:24px;margin-bottom: 26px;color: rgba(255, 255, 255, 0.8);">{{ $t('withdraw.xx') }}</div>
                <div class="select-box">
                  <el-select v-model="value" placeholder="" value-key="id" @change="changeLang($event)">
                    <el-option v-for="item in getData" :key="item.id" :label="item.coin_name" :value="item">
                    </el-option>
                  </el-select>
                </div>
                <div style="margin-bottom: 22px;">
                  <div class="select-box-tit">{{ $t('withdraw.ze') }}</div>
                  <div class="select-box-num">{{ (num * 1 + lock_num * 1).toFixed(6) }} {{ name }}</div>
                </div>
                <div style="margin-bottom: 22px;">
                  <div class="select-box-tit"> {{ $t('withdraw.kyye') }}</div>
                  <div class="select-box-num">{{ (num * 1).toFixed(6) }} {{ name }}</div>
                </div>
                <div>
                  <div class="select-box-tit">{{ $t('withdraw.djye') }}</div>
                  <div class="select-box-num">{{ (lock_num * 1).toFixed(6) }} {{ name }}</div>
                </div>

              </div>

            </div>
            <div class="main-footer-right">
              <div style="width: 70%;">
                <div style="font-size:14px;margin-bottom:16px;color: rgba(255, 255, 255, 0.8);padding-top: 24px;">
                  {{ $t('withdraw.txdz') }}
                </div>
                <div class="select-box">
                  <input type="text" class="select-box-ipt" :placeholder=" $t('withdraw.srdz')" v-model="address">
                </div>
                <div style="font-size:14px;margin-bottom:16px;color: rgba(255, 255, 255, 0.8);">
                  {{ $t('withdraw.txwl') }}
                </div>
                <div class="select-box">
                  <el-select v-model="value2" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div style="font-size:14px;margin-bottom:16px;color: rgba(255, 255, 255, 0.8);">
                  {{ $t('withdraw.txsl') }}
                </div>
                <div class="select-box">
                  <input type="number" min="0" class="select-box-ipt"
                         :placeholder="$t('withdraw.zx')+config.mix_money+name" v-model="money">
                  <div class="all" @click="setAll">{{ $t('withdraw.qb') }}</div>
                </div>
                <div class="serve-flex">
                  <div>{{ $t('withdraw.sxf') }}：{{ config.proportion }} {{ name }}</div>
                  <div>{{ $t('withdraw.sjdz') }}：{{ withdraw_money }}</div>
                </div>
                <div style="font-size:14px;margin-bottom:16px;color: rgba(255, 255, 255, 0.8);">
                  {{ $t('withdraw.jyma') }}
                </div>
                <div class="select-box" style="margin-bottom: 16px;">
                  <input type="password" class="select-box-ipt" :placeholder="$t('withdraw.qsr')" v-model="password">
                  <div class="all" @click="setPayPass">{{ $t('withdraw.szma') }}</div>
                </div>
                <div class="submit" @click="setWithdraw">{{ $t('withdraw.tj') }}</div>
                <div class="look-flex">
                  <img class="look-img" src="../../assets/market/zy.png" alt="">
                  <div class="look-tit">{{ $t('withdraw.wxts') }}</div>
                </div>
                <div class="look-word">{{ $t('withdraw.wxts2') }}</div>
              </div>


            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import {getCoinItem, getCoinList} from "@/api/money";
import {getWithdrawConfig, setWithdraw} from "@/api/withdraw";

export default {
  data() {
    return {
      name: 'USDT',
      value: 'USDT',
      value2: 'TRC20',
      getData: [],
      options:
        [
          {
            value: '1',
            label: 'TRC20'
          }
        ],
      num: '',
      address: '',
      money: '',
      withdraw_money: '0',
      lock_num: '',
      listObject: {},
      coin_name: '',
      coinId: 1,
      password: '',
      config: {
        mix_money: 1,
        proportion: 0.1,
      }
    }
  },
  components: {
    footerb,
    heads
  },
  created() {
    this.coinId = this.$route.query.coinId * 1
    if (!this.coinId) {
      this.coinId = 2
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    'money': function () {
      if (this.money === '' || this.money < this.config.proportion) {
        this.withdraw_money = 0
      } else {
        this.withdraw_money = (this.money - this.config.proportion).toFixed(6)
      }
    }
  },
  methods: {
    setPayPass(){
      this.$router.push({
        path: '/editpwd',
        query: {
          type: 3
        }
      })
    },
    setWithdraw() {
      setWithdraw({
        'pay_pass': this.password,
        'iconId': this.coinId,
        'toAddress': this.address,
        'money': this.money
      }).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
          this.getInfo(this.coinId)
        }
      })
    },
    setAll() {
      this.money = this.num
    },
    getList() {
      getCoinList().then(res => {
        if (res.code === 1) {
          res.data.items.forEach((item) => {
            if (item.is_recharge === 1) {
              this.getData.push(item)
              if (item.coin_id === this.coinId) {
                this.num = item.num
                this.lock_num = item.lock_num
                this.value = item.coin_name
                this.getInfo(item.coin_id)
              }
            }
            console.log(this.getData)
          })
        }
      })
    },
    getInfo(id) {
      this.getWithdrawConfig(id)
      getCoinItem({
        coinId: id,
      }).then(res => {
        if (res.code === 1) {
          this.listObject = res.data;
          this.num = res.data.num
          this.lock_num = res.data.lock_num
          this.value = res.data.coin_name
          this.name = res.data.coin_name;
          this.money = ''
          this.password = ''
          if (this.listObject.coin_id === 2) {
            this.value2 = 'TRC20';
            this.options = [
              {
                value: '1',
                label: 'TRC20'
              }
            ]
          } else {
            this.value2 = 'ERC20';
            this.options = [
              {
                value: '1',
                label: 'ERC20'
              }
            ]
          }
        }
      })

    },
    getWithdrawConfig(id) {
      getWithdrawConfig({
        coin_id: id,
      }).then(res => {
        if (res.code === 1) {
          this.config = res.data
        }
      })
    },
    changeLang(e) {
      console.log(e)
      if (e.is_recharge === 1) {
        this.num = e.num
        this.lock_num = e.lock_num
        this.coin_name = e.coin_name
        this.coinId= e.coin_id
        this.getInfo(e.coin_id)
      } else {
        this.listObject.code_img = ''
        this.listObject.address = ''
      }

    },
  }
};

</script>
<style scoped>
html {
  background: #171e28;
}

.main {
  width: 100%;
  height: auto;
  background: #1F282F;
  padding: 30px;
  box-sizing: border-box;
}

.main-head {
  width: 100%;
  height: auto;
  background: #171E28;
  margin-bottom: 20px;
  color: #FFFFFF;
}

.main-head-tit {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  padding: 16px 30px;
}

.line {
  width: 100%;
  height: 1px;
  background: #29343D;
}

.circle {
  width: 48px;
  height: 48px;
  background: #FFC300;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: #212121;
  margin-right: 30px;
}

.line2 {
  width: 60%;
  height: 2px;
  background: #FFC300;
}

.main-box {
  width: 100%;
  display: flex;
  padding: 68px 74px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-box-son {
  width: 33%;
}

.main-box-son-tit {
  max-width: 228px;
  font-size: 18px;
  color: #FFF;
  margin-bottom: 6px;
}

.main-box-son-tit2 {
  max-width: 228px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.main-footer {
  width: 100%;
  padding: 30px 40px;
  box-sizing: border-box;
  display: flex;
}

.select-box {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background: #29343D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

/deep/ .el-input__inner {
  background: none !important;
  border: none !important;
  color: #FFFFFF;
  width: 100%;
  padding: 0;
  line-height: 0;
}

.select-box-tit {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
}

.select-box-num {
  font-size: 20px;

}

.main-footer-left {
  width: 50%;
}

.main-footer-right {
  width: 50%;
}

.hint {
  font-size: 12px;
  color: #FF6B65;
  margin-bottom: 54px;
}

.address-tit {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 16px;
}

.code-box {
  width: 128px;
  height: 128px;
  margin-bottom: 20px;
  background: #FFF;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.code-box img {
  width: 100%;
  height: 100%;
}

.address-box {
  width: 100%;
  height: 36px;
  background: rgba(41, 52, 61, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  padding-left: 16px;
  margin-bottom: 44px;
}

.address-box-img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.look-flex {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.look-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.look-tit {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.look-word {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.select-box-ipt {
  width: 80%;
  color: #FFF;
}

.all {
  font-size: 12px;
  color: #FFC300;
  margin-right: 16px;
  cursor: pointer;
  text-align: right;
}

.serve-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.submit {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background: #FFC300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #212121;
  margin-bottom: 10px;
  cursor: pointer;
}

/deep/ .el-select {
  width: 100%;
}
</style>
